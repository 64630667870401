import React from "react"
import { Layout,Content } from "src/components/common"

const PrivacyPage = () => (
  <Layout>
    <Content content="privacy" />
  </Layout>
)

export default PrivacyPage
